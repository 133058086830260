import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`1. GENERAL`}</h2>
    <br />
    <p>{`Thank you for using Trailguide. Please read through these terms
and conditions closely. Please also read our
`}<a parentName="p" {...{
        "href": "/privacy.pdf"
      }}>{`Privacy Policy`}</a>{` which is part of this user agreement.`}</p>
    <br />
    <p>{`This Agreement is made between you and Trailguide AS (org. nr
918471200no) and its successors and assigns, and governs your
use of Trailguide. Using Trailguide means that you must accept
all of the terms and conditions contained in this Agreement.`}</p>
    <br />
    <p>{`By visiting and/or using the webpages of Trailguide and related
services, you confirm that you accept and will follow these
terms and conditions. If you do not accept these terms and
conditions, please leave the web site now.`}</p>
    <br />
    <p>{`These terms and conditions have been made with the intent of
further developing Trailguide as a free service for trail
bikers.`}</p>
    <br />
    <p>{`This Agreement is regulated by Norwegian law. In case of a
dispute, the venue will be Oslo City Court.`}</p>
    <br />
    <h2>{`2. INFORMATION ABOUT TRAILGUIDE`}</h2>
    <br />
    <p>{`Trailguide is a user-generated guide for trail biking where you
can find trails where you are, find information about trails
other places, help locate yourself, and share trail information
with other trail bikers. Trailguide is developed by bikers for
bikers and is free of cost to use for non-commercial usage. It works on PC,
Mac, iPhone, Android, iPads, tablets, Tesla cars and more.`}</p>
    <br />
    <p>{`Trailguide is owned by Trailguide AS (org. nr. 918471200no), Tuppeskogvegen 477,
3550 Gol, Norway.`}</p>
    <br />
    <p>{`Trailguide currently includes the following web sites;
trailguide.net, trailguide.no, trailguide.es, trailguide.fr,
trailguide.it, trailguide.pl, trailguide.se, trailguide.dk. Further web sites
may be created as needed and will be covered by this Agreement.`}</p>
    <br />
    <h2>{`3. RIGHTS`}</h2>
    <br />
    <p>{`Trailguide AS has all the rights to Trailguide and all the
content published on Trailguide's web sites. Content and
information from Trailguide cannot be reproduced, published,
distributed, or otherwise be used for public or commercial use
without written consent from Trailguide AS. End users of
Trailguide can freely download, print, copy, and show content
from Trailguide for private usage.`}</p>
    <br />
    <p>{`Users of Trailguide can donate trail information and other
relevant content to Trailguide. If you upload information of any
kind to the web sites of Trailguide you have donated this
information to Trailguide AS free of charge. By donating the
information, you give Trailguide AS the right to use, reproduce,
modify, display, perform, sublicense and distribute the
information (or portions thereof) with or without modifications,
or as part of a larger work for any purpose and without credit
to you.`}</p>
    <br />
    <p>{`Trailguide AS reserves the right to decide whether the
donated information will be published, including deleting content
published for marketing, incorrect, illegal, or offending content.`}</p>
    <br />
    <p>{`By donating trail information or other relevant content on
Traiguide, you contribute to developing a complete online guide
for the trail biking community while at the same time contribute
to keeping Trailguide free to use. Users that donate trail
information can edit the information they themselves have donated.`}</p>
    <br />
    <p>{`By donating trail information or other relevant content intentended for
publishing on Trailguide, the user guarantees that the material is not owned by
a third party or that a third party has the rights to the content that can limit
the use. Trailguide AS reserves the right to use the content in marketing
Trailguide and other commercial use of Trailguide. If any of the photos are
used, the Photographer will be credited.`}</p>
    <br />
    <h2>{`4. RESPONSIBILITIES`}</h2>
    <br />
    <p>{`Trailguide AS disclaims all responsibility for content published on Trailguide.
Trailguide AS does not take any responsibility for any inaccuracy or missing
information or that the information published is at any and all times updated.
Any decision taken on the basis of information published on Trailguide is done
so by you on your own responsibility.`}</p>
    <br />
    <p>{`Trailguide AS can not guarantee a continuous availability of the services.
Because of maintenance, security or capacity reasons, Trailguide AS reserves
the right to limit or interrupt the services as needed for the reasons mentioned.`}</p>
    <br />
    <p>{`As a service to the users of Trailguide, the web pages can contain links to
other web sites. Trailguide AS will try to their best ability linking to
serious web sites. Trailguide AS disclaim all responsibility for the content
being linked to from Trailguide.`}</p>
    <br />
    <h2>{`5. REGISTRATION OF USAGE DATA`}</h2>
    <br />
    <p>{`Users of Trailguide will not have any information other than a session ID on
the servers of the Service as long as the user does not actively supply
information (such as logging in using the Facebook login button). Google
Analytics information is stored with the intent of analysing the traffic. If
the user uses the location service, the location found (longitude, latitude,
and height above sea level) is stored on the servers of Trailguide. For full
information about the data collected and its intended use, please read our
`}<a parentName="p" {...{
        "href": "/privacy.pdf"
      }}>{`Privacy Policy`}</a>{`.`}</p>
    <br />
    <h2>{`6. FINAL PROVISIONS`}</h2>
    <br />
    <p>{`Trailguide AS reserves the right to change this terms of use at any time and
will inform users and customers about the changes.`}</p>
    <br />
Gol, Norway
    <br />
February 23rd, 2021

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      